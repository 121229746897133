<template>
  <v-row>
    <v-col cols="12">
      <search-card
        @clicked="(term) => getItems(term, this.$route.query.page)"
      />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="pagination.total"
        :loading="loading"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item.user_id="{ item }">
          <v-chip
            @click.stop="
              if (item && item.user_id) {
                $router.push({
                  name: 'dashboard-user-logs',
                  params: { user_id: item.user_id },
                  query: { parent_page: $route.query.page },
                });
              }
            "
            small
            outlined
          >
            {{ (item.user ? item.user.display_name : "N/A").toUpperCase() }}
          </v-chip>
        </template>

        <template v-slot:item.data="{ item }">
          <div v-for="(val, key) in item.data" :key="key">
            <div
              :class="
                $store.getters.isThemeDark ? 'yellow--text' : 'blue--text'
              "
            >
              {{ key.length ? $lang(key).toUpperCase() : "NA" }}
            </div>
            <div>{{ val }}</div>
          </div>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formateDate }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn class="mx-1" small icon @click.stop="() => removeItem(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        fab
        fixed
        top
        small
        color="red white--text"
        right
        @click.stop="clearAll"
        style="z-index: 100"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <query-page-redirect :pagination="pagination" />
      <!-- <v-pagination
        v-model="pagination.page"
        :length="pagination.lastPage"
      ></v-pagination> -->
    </v-col>
    <fab-button
      :icon="'mdi-plus'"
      color="primary"
      @clicked="$router.push({ name: 'dashboard-logs-create' })"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import { axios } from "@/plugins/axios";
import SearchCard from "../../prep/modules/SearchCard";
import QueryPageRedirect from "@/components/QueryPageRedirect.vue";
import bus from "@/bus";
import moment from "@/plugins/moment";

export default {
  name: "dashboard-logs",
  components: {
    FabButton,
    SearchCard,
    QueryPageRedirect,
  },
  data() {
    return {
      items: [],
      options: {},
      loading: false,
      where: "",
      headers: [
        { text: "Level", value: "level", sortable: true },
        { text: "Event", value: "event", sortable: true },
        { text: "Data", value: "data", sortable: true },
        { text: "User", value: "user_id", sortable: true },
        { text: "Ref ID", value: "ref_id", sortable: true },
        { text: "Row ID", value: "row_id", sortable: true },
        { text: "Time", value: "created_at", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  filters: {
    formateDate(value) {
      return moment(String(value)).format("DD/MMM/YYYY hh:mm A");
    },
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
    "pagination.page"(page) {
      if (page > 0) {
        //this.getItems();
      }
    },
  },
  methods: {
    removeItem(clickedItem) {
      bus.$emit("showWait");
      return axios
        .delete("logs/" + clickedItem.id)
        .then((res) => {
          this.items = this.items.filter((el) => {
            return el.id != clickedItem.id;
          });
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    clearAll() {
      if (
        !confirm(
          `Are you sure to clear all(${this.pagination.total}) logs?\r\nThis cant be undone.`
        )
      ) {
        return; //Promise.reject("CANCELED_BY_USER");
      }
      bus.$emit("showWait");
      return axios
        .delete("logs/clear")
        .then((res) => {
          return this.getItems("", 1);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    getItems(term = "", currPage) {
      if (!currPage) {
        currPage = this.$route.query.page;
      }
      this.where = term;
      this.loading = true;
      bus.$emit("showWait");
      const { sortBy, sortDesc } = this.options;
      const ascDesc = sortDesc && sortDesc[0] ? "desc" : "asc";

      return axios
        .get(`/logs`, {
          params: {
            page: currPage ? currPage : 1,
            orderby:
              sortBy && sortBy.length > 0 ? `${sortBy[0]}|${ascDesc}` : ``,
            where: this.where,
            perPage: 20,
          },
        })
        .then((res) => {
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
          this.items = res.data.data;
          return Promise.resolve(res.data);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    // this.getItems("", this.$route.query.page);
  },
  updated() {
    bus.$emit("highlightElement");
  },
};
</script>
