var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('search-card',{on:{"clicked":(term) => _vm.getItems(term, this.$route.query.page)}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user_id",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","outlined":""},on:{"click":function($event){$event.stopPropagation();if (item && item.user_id) {
              _vm.$router.push({
                name: 'dashboard-user-logs',
                params: { user_id: item.user_id },
                query: { parent_page: _vm.$route.query.page },
              });
            }}}},[_vm._v(" "+_vm._s((item.user ? item.user.display_name : "N/A").toUpperCase())+" ")])]}},{key:"item.data",fn:function({ item }){return _vm._l((item.data),function(val,key){return _c('div',{key:key},[_c('div',{class:_vm.$store.getters.isThemeDark ? 'yellow--text' : 'blue--text'},[_vm._v(" "+_vm._s(key.length ? _vm.$lang(key).toUpperCase() : "NA")+" ")]),_c('div',[_vm._v(_vm._s(val))])])})}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formateDate")(item.created_at))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return (() => _vm.removeItem(item)).apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('v-btn',{staticStyle:{"z-index":"100"},attrs:{"fab":"","fixed":"","top":"","small":"","color":"red white--text","right":""},on:{"click":function($event){$event.stopPropagation();return _vm.clearAll.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('query-page-redirect',{attrs:{"pagination":_vm.pagination}})],1),_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({ name: 'dashboard-logs-create' })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }